import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/modules/authentication/interfaces/user';
import { DataService } from 'src/app/modules/recipe-list/services/data.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  userSubscription: Subscription;
  user: User;

  constructor(private router: Router, public authService: AuthService) { }

  ngOnInit() {
    this.userSubscription = this.authService.getUser().subscribe(user => {
      this.user = user;
    });

  }


  doLogin() {
    this.authService.googleLogin();
  }

  doLogOut() {
    this.authService.doLogOut();
  }



}
