import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from } from 'rxjs';
import * as firebase from "firebase/app";
export class HttpCallInterceptor {
    constructor(injector, authService) {
        this.injector = injector;
        this.authService = authService;
    }
    intercept(request, next) {
        return from(this.handle(request, next));
    }
    handle(req, next) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.getToken().then(token => {
                return token;
            }).catch(() => {
                return null;
            });
            const authReq = req.clone({
                setHeaders: {
                    Authorization: `${token}`
                }
            });
            return next.handle(authReq).toPromise();
        });
    }
    getToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return firebase.auth().currentUser.getIdToken();
        });
    }
}
