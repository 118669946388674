import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthService } from '../modules/authentication/services/auth.service';
import * as firebase from "firebase/app";

@Injectable()
export class HttpCallInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        

        return from(this.handle(request, next));

    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        const token = await this.getToken().then(token => {
                            return token;
                        }).catch(() => {
                            return null;
                        });
        const authReq = req.clone({
            setHeaders: {
                Authorization: `${token}`
            }
        })

        return next.handle(authReq).toPromise();
    }


    async getToken(): Promise<any> {

        return firebase.auth().currentUser.getIdToken();

    }
}