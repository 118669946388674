export interface Roles {
    reader:     boolean;
    author?:    boolean;
    admin?:     boolean;
}

export class User {
    uid:         string;
    displayName: string;
    email?:      string;
    photoURL?:   string;
    roles?:      Roles;
    lastUpdateAt: string;

    constructor(authData) {
        this.uid         = authData.uid;
        this.displayName = authData.displayName;
        this.email       = authData.email;
        this.photoURL    = authData.photoURL;
        this.lastUpdateAt = new Date().getTime().toString();
    }
}