import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../interfaces/user";
import { switchMap, take, retry, first } from "rxjs/operators";
import { of } from "rxjs";
import { LoginObject } from "../interfaces/login.inteface";
import { RegisterObject } from "../interfaces/register.interface";
import { TestObject } from 'protractor/built/driverProviders';
import { resolve } from 'url';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) {
    this.afAuth.authState
      .pipe(
        switchMap(auth => {
          if (auth) {
            return this.db
              .collection("users")
              .doc(auth.uid)
              .valueChanges();
          } else {
            return of(null);
          }
        })
      )
      .subscribe(user => {
        this.user.next(user);
      });
  }

  async getUserIDAsync() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    console.log(user);
    return user.uid;
  }

  getUserUid(): string {
    return this.user.value.uid;
  }

  getUser(): Observable<User> {
    return this.user.asObservable();
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider).then(credential => {
      this.user.next(new User(credential.user));
    });
  }

  doEmailRegister(register: RegisterObject): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(register.email, register.password)
        .then(
          credential => {
            this.afAuth.auth.currentUser
              .updateProfile({
                displayName: register.displayName,
                photoURL: register.photoURL
              })
              .then(user => {
                this.updateUser(this.afAuth.auth.currentUser);
              });
            resolve(credential);
          },
          err => reject(err)
        );
    });
  }

  updatePhotoURL(photoURL: string): Promise<any> {
   return new Promise<any>((resolve, reject) => {
     this.afAuth.auth.currentUser
      .updateProfile({
        photoURL: photoURL
      }).then(user =>{
        this.updateUser(this.afAuth.auth.currentUser);
        resolve(user);
      })
    });
  }

  doEmailLogin(login: LoginObject): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(login.username, login.password)
        .then(
          credential => {
            this.updateUser(credential.user);
            resolve(credential);
          },
          err => reject(err)
        );
    });
  }

  doLogOut() {
    this.afAuth.auth.signOut();
    this.user.next(null);
  }

  private updateUser(authData) {
    const userData = new User(authData);
    const testObject: User = { ...userData };
    delete testObject.roles;

    this.db.collection('users').doc(authData.uid)
      .snapshotChanges()
      .pipe(take(1))
      .subscribe(user => {
        if (user.payload.exists) {
          this.db.collection('users').doc(authData.uid).update(testObject);
        } else {
          this.db.collection('users').doc(authData.uid).set(testObject);
        }
      })
  }
}
