import { Routes } from '@angular/router';
const ɵ0 = () => import("./modules/recipe-list/recipe-list.module.ngfactory").then(m => m.RecipeListModuleNgFactory), ɵ1 = () => import("./modules/authentication/authentication.module.ngfactory").then(m => m.AuthenticationModuleNgFactory), ɵ2 = () => import("./modules/admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory), ɵ3 = () => import("./modules/recipe-item/recipe.module.ngfactory").then(m => m.RecipeModuleNgFactory), ɵ4 = () => import("./modules/shopping-list/shopping-list.module.ngfactory").then(m => m.ShoppingListModuleNgFactory);
const routes = [
    { path: '', loadChildren: ɵ0 },
    { path: 'u', loadChildren: ɵ1 },
    { path: 'admin', loadChildren: ɵ2 },
    { path: 'recipe/:id', loadChildren: ɵ3 },
    { path: 'shoppinglist', loadChildren: ɵ4 },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
