<div class="site-nav sticky-top mb-3">
    <div class="d-flex">
        <div class="mr-auto p-2 home">
            <div class="heading" [routerLink]="['/']">
                <h4>
                    Chefs Notes
                </h4>

            </div>
        </div>
        <div class="p-2">
            <div class="login-button" *ngIf="!user">
                <button class="btn btn-primary" [routerLink]="['u/login']">Log In</button>
            </div>
            <span *ngIf="user"><img [src]="user.photoURL" class="img-thumbnail img-fluid" [routerLink]="['u/user']" routerLinkActive="router-link-active" /></span>&nbsp;
        </div>
    </div>
</div>
<!-- <div class="offset-sm-1 col-sm-10 col-12"> -->
<router-outlet></router-outlet>

<!-- </div> -->