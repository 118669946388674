import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/recipe-list/recipe-list.module').then(m => m.RecipeListModule) },
  { path: 'u', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)},
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
  { path: 'recipe/:id', loadChildren: () => import('./modules/recipe-item/recipe.module').then(m => m.RecipeModule) },
  { path: 'shoppinglist', loadChildren: () => import('./modules/shopping-list/shopping-list.module').then(m => m.ShoppingListModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
